.countdown-item {
  box-shadow: 0px 0px 1.5px 0px rgba(0, 0, 0, 0.2) inset;
}

.event-rule {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    font-size: 0.875rem;
    line-height: normal;
    color: var(--T1);
  }

  strong, b {
    font-weight: 700;
  }

  p,
  span {
    font-size: 0.75rem;
    line-height: normal;
    color: var(--T2);
  }
}

.vip-header-card{
  :global {
    .adm-progress-bar {
      --track-color: #ced3df;
      --track-width: 0.25rem;
      --fill-color: #ffaf69;
    }
  }
}

.deposit-button{
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 0px 0px rgba(255, 255, 255, 0.20) inset;
}

