.bonus-item {
  :global {
    .adm-progress-bar {
      --track-color: #ced3df;
      --track-width: 0.25rem;
      --fill-color: #ffaf69;
    }
  }
}

.bonus-item-button {
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2),
    0px 1px 0px 0px rgba(255, 255, 255, 0.2) inset;
}

.countdown-item {
  box-shadow: 0px 0px 1.5px 0px rgba(0, 0, 0, 0.2) inset;
}
