.me-promotions-linear {
  background: linear-gradient(180deg, #fff 36.99%, rgba(255, 255, 255, 0) 100%);
}

.me-promotions-promotion-linear {
  background: linear-gradient(226deg, #ffdcdc 10.1%, #fff9f6 62.89%);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
}

.me-promotions-bonus-linear {
  background: linear-gradient(210deg, #FEB 7.94%, #FFFEF7 64.4%);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
}

.gray-stroke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.1);
}

.me-promotions-promotion-linear, .me-promotions-bonus-linear{
  :global{
    .adm-badge-dot{
      right: 0.25rem;
      top: 0.25rem;
    }
  }
}
