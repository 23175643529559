:root {
  --T1: #121410;
  --T2: #48505D;
  --T3: #8D97AB;
  --T4: #8D97AB;
  --T2-dark: rgba(255, 255, 255, 0.7);
  
  /* 品牌色 */
  --1: #00B512;
  --BG: #F4F5F8;
  /* 主辅助色 */
  --2: #F15802;

  --image: #E8EBEE;
}
