.goto-withdraw-modal-body{
  background-color: transparent;
  max-height: 100vh;
  :global{
    .adm-modal-content{
      padding: 0 0 .75rem;
    }
  }
}

.amount-shadow{
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

}

.claim-now-button{
  border-radius: 2.5rem;
  box-shadow: 0px 0px 12px 0px rgba(255, 183, 0, 0.50), -2px 2px 2px 0px rgba(255, 255, 255, 0.70) inset;
}
